<template>
  <div
      class="collapse navbar-collapse sub-menu-bar"
      id="navbarSupportedContent"
  >
    <ul id="nav" class="navbar-nav ms-auto">
      <li class="nav-item">
        <router-link to="/">Home</router-link>
      </li>

      <li class="nav-item">
        <router-link to="/about" class="">About</router-link>
      </li>

      <li class="nav-item dropdown">
        <a class="nav-link dropdown-toggle" href="#" id="navbarDropdown" role="button" data-bs-toggle="dropdown" aria-expanded="false">
          Solutions
        </a>
        <ul class="dropdown-menu" aria-labelledby="navbarDropdown">
          <li>
            <router-link to="/solutions" @click="scrollMeTo('sacco')" class="dropdown-item">Sacco and Microfinance</router-link>
          </li>
          <li>
            <router-link to="/solutions#property" class="dropdown-item">Property Management</router-link>
          </li>
          <li>
            <router-link to="/solutions#hr" class="dropdown-item">HR & Payroll</router-link>
          </li>
          <li>
            <router-link to="/solutions#financial" class="dropdown-item">Financial Accounting</router-link>
          </li>
          <li>
            <router-link to="/solutions#inventory" class="dropdown-item">Inventory Management</router-link>
          </li>
          <li>
            <router-link to="/solutions#crm" class="dropdown-item">CRM</router-link>
          </li>
        </ul>
      </li>


      <!--      <li class="nav-item">
        <router-link to="/features">Features</router-link>
      </li>-->
      <li class="nav-item">
        <router-link to="/faq">FAQs</router-link>
      </li>
      <li class="nav-item">
        <router-link to="/contact">Contact</router-link>
      </li>
    </ul>
  </div>
</template>

<script>
export default {
  name: "Navigation",
  methods: {
    scrollMeTo(refName) {
      var element = document.getElementById(refName);
      var top = element.offsetTop;

      window.scrollTo(0, top);
    },
  },
}
</script>

<style scoped>
.navbar-nav .nav-item a:hover, .navbar-nav .nav-item a.active {
  color: #950833;
}

.dropdown-menu {
  position: fixed;
  z-index: 99;
  background-color: rgba(255, 255, 255, 0.8);
  -webkit-backdrop-filter: blur(5px);
  backdrop-filter: blur(5px);
  -webkit-box-shadow: inset 0 -1px 0 0 rgba(0, 0, 0, 0.1);
  box-shadow: inset 0 -1px 0 0 rgba(0, 0, 0, 0.1);
  -webkit-transition: all 0.3s ease-out 0s;
  transition: all 0.3s ease-out 0s;
}
.dropdown-item{
  padding-left: 15px !important;
  padding-right: 15px !important;
  color: #333 !important;
}

.dropdown-item.active, .dropdown-item:active {
  color: #fff !important;
  text-decoration: none;
  background-color: #950833;
}
</style>