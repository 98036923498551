<template>
  <div class="">
    <form id="contactForm" @submit.prevent="sendEmail()">
       <div class="alert alert-warning" v-if="submitting">
            Sending, please wait....
          </div>
          <div class="alert alert-info" v-if="message!=null">
            {{message}}
          </div>
      <div class="form-floating mb-3">
        <input class="form-control" id="fullName" required v-model="myform.name" type="text" placeholder="Full name" data-sb-validations="required" />
        <label for="fullName">Full name</label>
        <div class="invalid-feedback" data-sb-feedback="fullName:required">Full name is required.</div>
      </div>
      <div class="row">
        <div class="col">
          <div class="form-floating mb-3">
            <input class="form-control" v-model="myform.email" required id="emailAddress" type="email" placeholder="Email Address" data-sb-validations="required,email" />
            <label for="emailAddress">Email Address</label>
            <div class="invalid-feedback" data-sb-feedback="emailAddress:required">Email Address is required.</div>
            <div class="invalid-feedback" data-sb-feedback="emailAddress:email">Email Address Email is not valid.</div>
          </div>
        </div>
        <div class="col">
          <div class="form-floating mb-3">
            <input class="form-control" v-model="myform.mobile" id="phoneNumber" type="text" placeholder="Phone number" data-sb-validations="" />
            <label for="phoneNumber">Phone number</label>
          </div>
        </div>
      </div>
      <div class="form-floating mb-3">
        <input class="form-control" v-model="myform.date" id="suggestedDate" required type="datetime-local" placeholder="Suggested date" data-sb-validations="" />
        <label for="suggestedDate">Suggested date</label>
      </div>
      <div class="form-floating mb-3">
        <textarea class="form-control" required id="message" v-model="myform.message" type="text" placeholder="Message" style="height: 6rem;" data-sb-validations=""></textarea>
        <label for="message">Message</label>
      </div>
        <button class="btn btn-primary mt-4" type="submit">Send Message</button>
    </form>
  </div>
</template>

<script>
import axios from "axios";

export default {
  data(){
    return {
        // endpoint: "http://localhost:8000/request_demo.php",
        message: null,
        submitting: false,
        myform: {
            name: null,
            email: null,
            mobile: null,
            date: null,
            message: null,
        }
    }
  },
  props: {
    form: {}
  },
  name: "DemoFormComponent",
  methods :{
    sendEmail() {
        try{
          this.submitting = true;
          axios.post('https://ezenfinancials.com/apis/demo-requests.php', {
                    name:this.myform.name,
					email:this.myform.email,
					mobile:this.myform.mobile,
					date:this.myform.date,
					message:this.myform.message
                }
            )
            .then((result)=>{
                console.log(result);
                this.message = result.data;//Thank You for showing your interest in our product. We'll get in touch soonest.."
            })
            .finally(()=>{
                this.submitting = false;
            })
        }
        catch(error) {
            console.log(error);
        }
    },
    // submitForm: function (e) {
    //   e.preventDefault();
    //   console.log(this.form);
    //   this.submitting=true;
    //   axios.post(this.endpoint,this.form).then(res => {
    //     console.log(res);
    //     this.submitting=false;
    //     this.message ="Thank You for showing your interest in our product. We'll get in touch soonest.."
    //     setTimeout(() => {
    //       this.form={};
    //       }, 500);
    //     }).catch(error => {
    //       this.submitting=false;
    //       console.log(error)
    //   })
    // }
  }
};
</script>

<style scoped>
.contact .info i {
  font-size: 20px;
  color: #c21a1a;
  float: left;
  width: 44px;
  height: 44px;
  background: #FFCCCB;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50px;
  transition: all 0.3s ease-in-out;
}
.contact .info h4 {
  padding: 0 0 0 60px;
  font-size: 22px;
  font-weight: 600;
  margin-bottom: 5px;
  color: #c21a1a;
}

.contact .info p {
  padding: 0 0 10px 60px;
  margin-bottom: 20px;
  font-size: 14px;
  color: #3d3c3a;
}
.contact .info .email:hover i, .contact .info .address:hover i, .contact .info .phone:hover i {
  background: #c21a1a;
  color: #fff;
}

.form-control {
  border: none;
  border-radius: 0;
  border-bottom: solid 1px #9b0917;
}

.form-control:focus {
  color: #212529;
  background-color: #fff;
  border-color: inherit;
  outline: 0;
  box-shadow: none;
}
</style>