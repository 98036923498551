<template>
  <footer id="footer" class="footer-area pt-120">
    <div class="container">
      <div
          class="subscribe-area wow fadeIn"
          data-wow-duration="1s"
          data-wow-delay="0.5s"
      >
        <div class="row">
          <div class="col-lg-6">
            <div class="subscribe-content mt-45">
              <h2 class="subscribe-title">
                Subscribe Our Newsletter <span>get reguler updates</span>
              </h2>
            </div>
          </div>
          <div class="col-lg-6">
            <div class="subscribe-form mt-50">
              <form action="#">
                <input type="text" placeholder="Enter eamil" />
                <button class="main-btn">Subscribe</button>
              </form>
            </div>
          </div>
        </div>
        <!-- row -->
      </div>
      <!-- subscribe area -->
      <div class="footer-widget pb-100">
        <div class="row">
          <div class="col-lg-5 col-md-7 col-sm-8">
            <div
                class="footer-about mt-50 wow fadeIn"
                data-wow-duration="1s"
                data-wow-delay="0.2s"
            >
              <a class="logo" href="javascript:void(0)">
                <img src="/assets/img/logo/logo-2.svg" alt="Logo" />
              </a>
              <p class="text">
                Ezen Financials is an all-in-one web based Financial ERP for Microfinance, Sacco,
                Property Management, Property sales, Valuation, CRM, HR & Payroll and Financial Accounting
                that makes business life easier for Saccos, Microfinance, Property managers, landlords and
                any other business.
              </p>
              <ul class="social">
                <li>
                  <a href="javascript:void(0)">
                    <i class="lni lni-facebook-filled"> </i>
                  </a>
                </li>
                <li>
                  <a href="https://twitter.com/EzenPartners" target="_blank">
                    <i class="lni lni-twitter-filled"> </i>
                  </a>
                </li>
                <li>
                  <a href="https://www.instagram.com/ezen.partners_/" target="_blank">
                    <i class="lni lni-instagram-filled"> </i>
                  </a>
                </li>
                <li>
                  <a href="javascript:void(0)">
                    <i class="lni lni-linkedin-original"> </i>
                  </a>
                </li>
              </ul>
            </div>
            <!-- footer about -->
          </div>
          <div class="col-lg-4 col-md-6 col-sm-12">
            <div class="footer-link d-flex mt-50 justify-content-sm-between">
              <!-- footer wrapper -->
              <div
                  class="link-wrapper wow fadeIn"
                  data-wow-duration="1s"
                  data-wow-delay="0.6s"
              >
                <div class="footer-title">
                  <h4 class="title">Quick Links</h4>
                </div>
                <ul class="link">
                  <li>
                    <router-link to="/" class="active">Home</router-link>
                  </li>

                  <li>
                    <router-link to="/about" class="">About</router-link>
                  </li>

                  <li>
                    <router-link to="/faq">FAQs</router-link>
                  </li>
                  <li>
                    <router-link to="/contact">Contact</router-link>
                  </li>
                  <li>
                    <router-link to="/privacy_policy">Privacy Policy</router-link>
                  </li>
                </ul>
              </div>
              <!-- footer wrapper -->
            </div>
            <!-- footer link -->
          </div>
          <div class="col-lg-3 col-md-5 col-sm-12">
            <div
                class="footer-contact mt-50 wow fadeIn"
                data-wow-duration="1s"
                data-wow-delay="0.8s"
            >
              <div class="footer-title">
                <h4 class="title">Contact Us</h4>
              </div>
              <ul class="contact">
                <li>+254 729 523 191</li>
                <li>+254 720 893 752</li>
                <li>info@ezenfinancials.com</li>
<!--                <li>solutions@ezenfinancials.com</li>-->
<!--                <li>www.yourweb.com</li>-->
                <li>
                  7th Floor, Twiga Towers, Muranga<br />
                  Road - Nairobi
                </li>
                <li>
                  <router-link to="/new_privacy_policy">New Privacy Policy</router-link>
                </li>
                <li>
                  <router-link to="/terms_of_service">Terms of Service</router-link>
                </li>
              </ul>
            </div>
            <!-- footer contact -->
          </div>
        </div>
        <!-- row -->
      </div>
      <!-- footer widget -->
      <div class="footer-copyright">
        <div class="row">
          <div class="col-lg-12">
            <div class="copyright d-sm-flex justify-content-between">
              <div class="copyright-content">
                <p class="text">
                  &copy; Copyright {{currentYear}} Ezen Financials . All Rights Reserved.
                </p>
              </div>
              <!-- copyright content -->
            </div>
            <!-- copyright -->
          </div>
        </div>
        <!-- row -->
      </div>
      <!-- footer copyright -->
    </div>
    <!-- container -->
    <div id="particles-2"></div>
  </footer>
</template>

<script>
export default {
  name: "Footer",
  computed: {
    currentYear: () => new Date().getFullYear()
  }
}
</script>

<style scoped>
.subscribe-form button {
  background: -webkit-gradient(linear, left top, right top, from(#9b0917), color-stop(50%, #950833), to(#2a0004));
  background: linear-gradient(to right, #9b0917 0%, #950833 50%, #2a0004 100%);
  background-size: 200%;
}
.back-to-top {
  background: -webkit-gradient(linear, left top, right top, from(#9b0917), color-stop(50%, #950833), to(#2a0004));
  background: linear-gradient(to right, #9b0917 0%, #950833 50%, #2a0004 100%);
  background-size: 200%;
}

</style>