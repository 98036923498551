<template>
<div>
  <div class="container">
    <div class="row justify-content-center pt-100">
      <div class="col-lg-8">
        <div class="text-center">
          <h3
              class="header-sub-title wow fadeInUp"
              data-wow-duration="1.3s"
              data-wow-delay="0.2s"
          >
            About <b>Ezen Financials</b>
          </h3>
          <h2
              class="header-title wow fadeInUp"
              data-wow-duration="1.3s"
              data-wow-delay="0.5s"
          >
            Get to know who we are and meet our amazing team
          </h2>
          <!--              <p
                            class="text wow fadeInUp"
                            data-wow-duration="1.3s"
                            data-wow-delay="0.8s"
                        >
                          Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed
                          diam nonumy eirmod tempor
                        </p>-->
        </div>
        <!-- header hero content -->
      </div>
    </div>
    <!-- row -->

    <!-- row -->
  </div>

  <section id="facts" class="video-counter pt-70">
    <div class="container">
      <div class="row">
        <div class="col-lg-6 order-lg-last">
          <div
              class="counter-wrapper mt-50 wow fadeIn"
              data-wow-duration="1s"
              data-wow-delay="0.8s"
          >
            <div class="counter-content">
              <div class="section-title">
                <div class="line"></div>
                <h3 class="title">About <span> Ezen Financials</span></h3>
              </div>
              <!-- section title -->
              <p class="text lead fw-normal">
                Since 2013 we’re a Swiss Army knife when it comes to automated and efficient property management solutions that enable landlords and managers of residential and association properties to take complete control of every aspect of their business, including the rent, vacancy and maintenance cycles.
              </p>
              <p class="text">
                It is amazingly great how Ezen streamlines management of the rent cycle with key features such as tenant and lease tracking, full general ledger accounting, automated rent and late fee reminders and on-demand reports. Additionally, rent collection can be automated; and property managers can receive online payments via Ezen's tenant mobile application.
              </p>
            </div>
          </div>
          <!-- counter wrapper -->
        </div>
        <div class="col-lg-6">
          <div
              class="video-content mt-50 wow fadeIn"
              data-wow-duration="1s"
              data-wow-delay="0.5s"
          >
<!--            <img class="dots" src="assets/images/video/dots.svg" alt="dots" />-->
            <div class="video-wrapper">
              <div class="video-image">
                <img src="/assets/img/misc/about.jpg" alt="video" />
              </div>
            </div>
            <!-- video wrapper -->
          </div>
          <!-- video content -->
        </div>
      </div>
      <!-- row -->
    </div>
    <!-- container -->
  </section>

  <section id="team" class="team-area pt-120">
    <div class="container">
      <div class="row justify-content-center">
        <div class="col-lg-10">
          <div class="section-title text-center pb-30">
            <div class="line m-auto"></div>
            <h3 class="title"><span>Meet Our</span> Team of professionals</h3>
            <p class="mt-3">
              It’s the collected experience of our entire team that allows us to give you the best business solutions. Over 100,000 plus hours of service provided to over 1000 plus clients from over 50 years of combined specialist knowledge.
            </p>
          </div>
          <!-- section title -->
        </div>
      </div>
      <!-- row -->
      <div class="row justify-content-center">
        <div class="col-lg-4 col-md-7 col-sm-8" v-for="(person) in people" :key="person.name">
          <TeamMember :image="person.image" :name="person.name" :position="person.position" :socials="person.socials" />
          <!-- single team -->
        </div>
      </div>
      <!-- row -->
    </div>
    <!-- container -->
  </section>

</div>
</template>

<script>
import TeamMember from "@/components/TeamMember";
export default {
  name: "AboutPage",
  components: {TeamMember},
  metaInfo: {
    // title will be injected into parent titleTemplate
    title: 'About Us'
  },
  data: function () {
    return {
      people: [
        {
          name: 'Mike Bavon',
          position: 'Director Product Development',
          image: '/assets/img/staff/DSC_6176.jpg',
          socials: {facebook: '#', twitter: '#', instagram: '#', linkedin: '#'}
        },
        {
          name: 'Patricia Njeri',
          position: 'Director Business Development',
          image: '/assets/img/staff/DSC_6415.jpg',
          socials: {facebook: '#', twitter: '#', instagram: '#', linkedin: '#'}
        },
        {
          name: 'Cynthia Muiruri',
          position: 'Sales Executive',
          image: '/assets/img/staff/DSC_6217.jpg',
          socials: {facebook: '#', twitter: '#', instagram: '#', linkedin: '#'}
        },
        {
          name: 'Victor Chacha',
          position: 'Senior Implementor',
          image: '/assets/img/staff/DSC_6208.jpg',
          socials: {facebook: '#', twitter: '#', instagram: '#', linkedin: '#'}
        },
        {
          name: 'Sarah Espilah',
          position: 'Administration Assistant',
          image: '/assets/img/staff/DSC_6224.jpg',
          socials: {facebook: '#', twitter: '#', instagram: '#', linkedin: '#'}
        },
        {
          name: 'David Ochieng',
          position: 'Lead Software Developer',
          image: '/assets/img/staff/DSC_6442.jpg',
          socials: {facebook: '#', twitter: '#', instagram: '#', linkedin: '#'}
        },
        {
          name: 'Joel Ochieng',
          position: 'Software Developer & Technical Specialist',
          image: '/assets/img/staff/DSC_6170.jpg',
          socials: {facebook: '#', twitter: '#', instagram: '#', linkedin: '#'}
        },
        {
          name: 'Samuel Kihika',
          position: 'Software Developer & Technical Specialist',
          image: '/assets/img/staff/DSC_6439.jpg',
          socials: {facebook: '#', twitter: '#', instagram: '#', linkedin: '#'}
        },
        {
          name: 'Annita Otiende',
          position: 'Software Implementation Specialist',
          image: '/assets/img/staff/DSC_6201.jpg',
          socials: {facebook: '#', twitter: '#', instagram: '#', linkedin: '#'}
        },
        {
          name: 'Collins Koech',
          position: 'Software Implementation Specialist',
          image: '/assets/img/staff/DSC_0828.jpg',
          socials: {facebook: '#', twitter: '#', instagram: '#', linkedin: '#'}
        },
        {
          name: 'David Kamau',
          position: 'Software Implementation Specialist',
          image: '/assets/img/staff/DSC_6198.jpg',
          socials: {facebook: '#', twitter: '#', instagram: '#', linkedin: '#'}
        },
        {
          name: 'Everest Odera',
          position: 'Software Implementation Specialist',
          image: '/assets/img/staff/DSC_0824.jpg',
          socials: {facebook: '#', twitter: '#', instagram: '#', linkedin: '#'}
        },
        {
          name: 'Samuel Otieno',
          position: 'Software Implementation Specialist',
          image: '/assets/img/staff/DSC_6212.jpg',
          socials: {facebook: '#', twitter: '#', instagram: '#', linkedin: '#'}
        },
        {
          name: 'Jackline Atieno',
          position: 'Office Messenger',
          image: '/assets/img/staff/DSC_6231.jpg',
          socials: {facebook: '#', twitter: '#', instagram: '#', linkedin: '#'}
        }
      ]
    }
  }
}
</script>

<style scoped>

</style>