<template>
<div>
    <div
        id="home"
        class="header-hero bg_cover"
        style="background-image: url('/assets/img/banner/banner-bg.svg')"
    >
      <div class="container">
        <div class="row justify-content-center">
          <div class="col-lg-8">
            <div class="header-hero-content text-center">
              <h2
                  class="header-title wow fadeInUp"
                  data-wow-duration="1.3s"
                  data-wow-delay="0.2s"
              >
                The Ultimate Financial ERP.
              </h2>
              <h3
                  class="header-sub-title wow fadeInUp"
                  data-wow-duration="1.3s"
                  data-wow-delay="0.5s"
              >
                Microfinance, Sacco, Property Management, Property sales, Valuation, CRM, HR & Payroll and Financial Accounting.
              </h3>
<!--              <p
                  class="text wow fadeInUp"
                  data-wow-duration="1.3s"
                  data-wow-delay="0.8s"
              >
                Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed
                diam nonumy eirmod tempor
              </p>-->
            </div>
            <!-- header hero content -->
          </div>
        </div>
        <!-- row -->
        <div class="row">
          <div class="col-lg-12">


            <!-- video content -->

            <div
                class="header-hero-image text-center wow fadeIn"
                data-wow-duration="1.3s"
                data-wow-delay="1.4s"
            >

              <div class="video-content">
                <img class="dots" src="/assets/images/video/dots.svg" alt="dots" />
                <div class="video-wrapper">
                  <div class="video-image">
<!--                    <img src="/assets/img/misc/home.jpeg" alt="video" />-->
                    <img src="/assets/img/pages/ezenfinancials.com-home-page.png" alt="video" />
                  </div>
                  <div class="video-icon">
                    <a
                        href="https://www.youtube.com/watch?v=5H6omNqPkpU"
                        class="video-popup glightbox"
                    >
                      <i class="lni lni-play"> </i>
                    </a>
                  </div>
                </div>
              </div>

<!--              <img src="assets/images/header/header-hero.png" alt="hero" />-->
            </div>
            <!-- header hero image -->
          </div>
        </div>
        <!-- row -->
      </div>
      <!-- container -->
      <div id="particles-1" class="particles"></div>
    </div>

    <!-- Clientelle -->
    <div class="brand-area pt-90">
      <div class="container">
        <div class="row">
           <h2
                  class="header-title wow fadeInUp text-center"
                  data-wow-duration="1.3s"
                  data-wow-delay="0.2s"
              >
                Trusted by
              </h2>
          <div class="col-lg-12">
            <div
                class="
                brand-logo
                d-flex
                align-items-center
                justify-content-center justify-content-md-between
              "
            >
              <div
                  class="single-logo mt-30 wow fadeIn"
                  data-wow-duration="1s"
                  data-wow-delay="0.2s"
              >
                <img src="/assets/img/clientelle/Tysons_Limited.png" alt="brand" />
              </div>
              <!-- single logo -->
              <div
                  class="single-logo mt-30 wow fadeIn"
                  data-wow-duration="1.5s"
                  data-wow-delay="0.2s"
              >
                <img src="/assets/img/clientelle/Crystal_Valuers_Limited.jpg" alt="brand" />
              </div>
              <!-- single logo -->
              <div
                  class="single-logo mt-30 wow fadeIn"
                  data-wow-duration="1.5s"
                  data-wow-delay="0.5s"
              >
                <img src="/assets/img/clientelle/Ebony_Estates_Ltd.png" alt="brand" />

              </div>
              <!-- single logo -->
              <div
                  class="single-logo mt-30 wow fadeIn"
                  data-wow-duration="1.5s"
                  data-wow-delay="0.4s"
              >
                <img src="/assets/img/clientelle/Masterways_Real_Estate.png" alt="brand" />
              </div>
              <!-- single logo -->
              <div
                  class="single-logo mt-30 wow fadeIn"
                  data-wow-duration="1.5s"
                  data-wow-delay="0.3s"
              >
                <img src="/assets/img/clientelle/Paragon_Property_Valuers_and_Consultants.jpg" alt="brand" />
              </div>

              <!-- single logo -->
            </div>
            <!-- brand logo -->
          </div>
        </div>
        <div class="row">
          <div class="col-lg-12">
            <div
                class="
                brand-logo
                d-flex
                align-items-center
                justify-content-center justify-content-md-between
              "
            >
              <div
                  class="single-logo mt-30 wow fadeIn"
                  data-wow-duration="1.5s"
                  data-wow-delay="0.5s"
              >
                <img src="/assets/img/clientelle/Olive_Joy_Care.jpeg" alt="brand" />

              </div>
              <div
                  class="single-logo mt-30 wow fadeIn"
                  data-wow-duration="1.5s"
                  data-wow-delay="0.5s"
              >
                <img src="/assets/img/clientelle/Promast_Enterprises.jpg" alt="brand" />

              </div>
              <div
                  class="single-logo mt-30 wow fadeIn"
                  data-wow-duration="1.5s"
                  data-wow-delay="0.5s"
              >
                <img src="/assets/img/clientelle/Ardhi_Sacco_Limited.png" alt="brand" />

              </div>
              <div
                  class="single-logo mt-30 wow fadeIn"
                  data-wow-duration="1.5s"
                  data-wow-delay="0.5s"
              >
                <strong>Mofaa Sacco Ltd</strong>
                <!-- <img src="/assets/img/clientelle/Mofaa_Sacco_Ltd.jpg" alt="brand" /> -->

              </div>
              <div
                  class="single-logo mt-30 wow fadeIn"
                  data-wow-duration="1.5s"
                  data-wow-delay="0.5s"
              >
                <img src="/assets/img/clientelle/Real_Management_Limited.png" alt="brand" />

              </div>
              <!-- single logo -->
            </div>
            <!-- brand logo -->
          </div>
        </div>
        <!-- row -->
      </div>
      <!-- container -->
    </div>

    <!-- Solutions -->
    <section id="solutions" class="services-area pt-120">
      <div class="container">
        <div class="row justify-content-center">
          <div class="col-lg-10">
            <div class="section-title text-center pb-40">
              <div class="line m-auto"></div>
              <h3 class="title">
                Solutions Overview
              </h3>
              <p class="mt-3">
                Ezen Financials is an all-in-one web based Financial ERP for Microfinance, Sacco,
                Property Management, Property sales, Valuation, CRM, HR & Payroll and Financial Accounting
                that makes business life easier for Saccos, Microfinance, Property managers, landlords and
                any other business.
              </p>
            </div>
            <!-- section title -->
          </div>
        </div>
        <!-- row -->
        <div class="row justify-content-center">

          <!-- Begin Property Management -->
          <div class="col-lg-4 col-md-4 col-sm-8">
            <div
                class="single-services text-center mt-30 wow fadeIn"
                data-wow-duration="1s"
                data-wow-delay="0.2s"
            >
              <div class="services-icon">
                <img
                    class="shape"
                    src="/assets/images/services/services-shape.svg"
                    alt="shape"
                />
                <img
                    class="shape-1"
                    src="/assets/images/services/services-shape-1.svg"
                    alt="shape"
                />
                <i class="lni lni-apartment"> </i>
              </div>
              <div class="services-content mt-30">
                <h4 class="services-title">
                  <a href="javascript:void(0)"  style="font-size:18px">Property Management and Sales</a>
                </h4>
                <p class="text">
                  A tool that drives success for property owners, agencies, developers and property managers around the world.
                   </p>
                <router-link to="/solutions#property" class="more"
                >Learn More <i class="lni lni-chevron-right"> </i>
                </router-link>
              </div>
            </div>
            <!-- single services -->
          </div>
          <!-- End Property Management -->

          <!-- Begin Sacco -->
          <div class="col-lg-4 col-md-4 col-sm-8">
            <div
                class="single-services text-center mt-30 wow fadeIn"
                data-wow-duration="1s"
                data-wow-delay="0.8s"
            >
              <div class="services-icon">
                <img
                    class="shape"
                    src="assets/images/services/services-shape.svg"
                    alt="shape"
                />
                <img
                    class="shape-1"
                    src="assets/images/services/services-shape-4.svg"
                    alt="shape"
                />
                <i class="lni lni-coin"> </i>
              </div>
              <div class="services-content mt-30">
                <h4 class="services-title">
                  <a href="javascript:void(0)" style="font-size:19px">Sacco and Microfinance</a>
                </h4>
                <p class="text">
                  A tool for Saccos and Microfinance. Helps you manage membership, savings, shares,loan applications and repayment.
                   </p>
                <router-link class="more" to="/solutions#sacco"
                >Learn More <i class="lni lni-chevron-right"> </i>
                </router-link>
              </div>
            </div>
            <!-- single services -->
          </div>
          <!-- End Sacco -->

          <!-- Begin HR -->
          <div class="col-lg-4 col-md-4 col-sm-8">
            <div
                class="single-services text-center mt-30 wow fadeIn"
                data-wow-duration="1s"
                data-wow-delay="0.8s"
            >
              <div class="services-icon">
                <img
                    class="shape"
                    src="assets/images/services/services-shape.svg"
                    alt="shape"
                />
                <img
                    class="shape-1"
                    src="assets/images/services/services-shape-3.svg"
                    alt="shape"
                />
                <i class="lni lni-users"> </i>
              </div>
              <div class="services-content mt-30">
                <h4 class="services-title">
                  <a href="javascript:void(0)" style="font-size:19px">HR &amp; Payroll</a>
                </h4>
                <p class="text">
                  Manage your human resources efficiently. Automate your payroll and save time. For smart HR and Payroll managers.</p>
                
                <router-link class="more mb-10" to="/solutions#hr"
                >Learn More <i class="lni lni-chevron-right"> </i>
                </router-link>
              </div>
            </div>
            <!-- single services -->
          </div>
          <!-- End HR -->

          <!-- Begin Financial Accounts -->
          <div class="col-lg-4 col-md-4 col-sm-8">
            <div
                class="single-services text-center mt-30 mb-2 wow fadeIn"
                data-wow-duration="1s"
                data-wow-delay="0.5s"
            >
              <div class="services-icon">
                <img
                    class="shape"
                    src="assets/images/services/services-shape.svg"
                    alt="shape"
                />
                <img
                    class="shape-1"
                    src="assets/images/services/services-shape-2.svg"
                    alt="shape"
                />
                <i class="lni lni-invest-monitor"> </i>
              </div>
              <div class="services-content mt-30">
                <h4 class="services-title">
                  <a href="javascript:void(0)" style="font-size:19px">Financial Accounting</a>
                </h4>
                <p class="text">
                  Get your financial insights instantly from the various financial statements 
                </p>
                <router-link class="more" to="solutions#financial"
                >Learn More <i class="lni lni-chevron-right"> </i>
                </router-link>
              </div>
            </div>
            <!-- single services -->
          </div>
          <!-- End Financial Accounts -->
          
          <!-- Begin CRM -->
          <div class="col-lg-4 col-md-4 col-sm-8">
            <div
                class="single-services text-center mt-30 wow fadeIn"
                data-wow-duration="1s"
                data-wow-delay="0.8s"
            >
              <div class="services-icon">
                <img
                    class="shape"
                    src="assets/images/services/services-shape.svg"
                    alt="shape"
                />
                <img
                    class="shape-1"
                    src="assets/images/services/services-shape-5.svg"
                    alt="shape"
                />
                <i class="lni lni-book"> </i>
              </div>
              <div class="services-content mt-30">
                <h4 class="services-title">
                  <a href="javascript:void(0)">CRM</a>
                </h4>
                <p class="text">
                  Our CRM helps you streamline processes, manage customer relationships, increase sales, and better your customer service.
                </p>
                <router-link class="more" to="/solutions#crm"
                >Learn More <i class="lni lni-chevron-right"> </i>
                </router-link>
              </div>
            </div>
            <!-- single services -->
          </div>
          <!-- End CRM -->

          <!-- Begin CRM -->
          <div class="col-lg-4 col-md-4 col-sm-8">
            <div
                class="single-services text-center mt-30 wow fadeIn"
                data-wow-duration="1s"
                data-wow-delay="0.8s"
            >
              <div class="services-icon">
                <img
                    class="shape"
                    src="assets/images/services/services-shape.svg"
                    alt="shape"
                />
                <img
                    class="shape-1"
                    src="assets/images/services/services-shape-1.svg"
                    alt="shape"
                />
                <i class="lni lni-cart-full"> </i>
              </div>
              <div class="services-content mt-30">
                <h4 class="services-title">
                  <a href="javascript:void(0)" style="font-size:19px">Inventory Management</a>
                </h4>
                <p class="text">
                 Manage Purchases,Sales,Stock Adjustements,Orders and many more with ease.
                </p>
                <router-link class="more" to="/solutions#crm"
                >Learn More <i class="lni lni-chevron-right"> </i>
                </router-link>
              </div>
            </div>
            <!-- single services -->
          </div>
          <!-- End CRM -->
        </div>
        <!-- row -->
      </div>
      <!-- container -->
    </section>

    <!-- Why Ezen? -->
    <section id="features" class="services-area pt-120">
      <div class="container">
        <div class="row justify-content-center">
          <div class="col-lg-10">
            <div class="section-title text-center pb-40">
              <div class="line m-auto"></div>
              <h3 class="title">
                Why Ezen Financials?
              </h3>
              <p class="mt-3">
                Ezen Financials has passed the usability test, users have rated it as user friendly and a product that is available whenever needed.
              </p>
            </div>
            <!-- section title -->
          </div>
        </div>
        <!-- row -->
        <div class="row justify-content-center">
          <div class="col-lg-4 col-md-7 col-sm-8">
            <div
                class="single-services feature text-center mt-30 wow fadeIn"
                data-wow-duration="1s"
                data-wow-delay="0.2s"
            >
              <div class="services-icon">

                <span class="feature_number">1</span>
              </div>
              <div class="services-content mt-30">
                <h6 class="services-title">
                  Security
                </h6>
                <p class="text">
                 Hosted on cloud servers for higher security and availability.<br>
                 Applies Web Application Firewall as a secondary security layer.<br/>
                 Uses Proxy servers for additional security layers and load-balancing. 
                </p>

              </div>
            </div>
            <!-- single services -->
          </div>
          <div class="col-lg-4 col-md-7 col-sm-8">
            <div
                class="single-services feature text-center mt-30 wow fadeIn"
                data-wow-duration="1s"
                data-wow-delay="0.2s"
            >
              <div class="services-icon">

                <span class="feature_number">2</span>
              </div>
              <div class="services-content mt-30">
                <h6 class="services-title">
                  Data Migration
                </h6>
                <p class="text">
                  Easy data migration when onboarding a new clients.
                </p>
                <br>
                <p>Easily export data from our system by a click of a button.</p>

              </div>
            </div>
            <!-- single services -->
          </div>
          <div class="col-lg-4 col-md-7 col-sm-8">
            <div
                class="single-services feature text-center mt-30 wow fadeIn"
                data-wow-duration="1s"
                data-wow-delay="0.2s"
            >
              <div class="services-icon">

                <span class="feature_number">3</span>
              </div>
              <div class="services-content mt-30">
                <h6 class="services-title">
                  Accounting & Reporting
                </h6>
                <p class="text">
                  Ezen comes with a tool for automated accounting and reporting for improved business insight and decision-making.
                </p>

              </div>
            </div>
            <!-- single services -->
          </div>
          <div class="col-lg-4 col-md-7 col-sm-8">
            <div
                class="single-services feature text-center mt-30 wow fadeIn"
                data-wow-duration="1s"
                data-wow-delay="0.2s"
            >
              <div class="services-icon">

                <span class="feature_number">4</span>
              </div>
              <div class="services-content mt-30">
                <h6 class="services-title">
                  SMS & Email
                </h6>
                <p class="text">
                  Send SMS and Emails to any of your clients, contacts and staff and keep records of what was sent..
                </p>

              </div>
            </div>
            <!-- single services -->
          </div>
          <div class="col-lg-4 col-md-7 col-sm-8">
            <div
                class="single-services feature text-center mt-30 wow fadeIn"
                data-wow-duration="1s"
                data-wow-delay="0.2s"
            >
              <div class="services-icon">

                <span class="feature_number">5</span>
              </div>
              <div class="services-content mt-30">
                <h6 class="services-title">
                  Instant Payments
                </h6>
                <p class="text">
                  Receive payment from your customers in real-time.
                </p>
                <p>
                  Integrated with M-PESA Paybill and Banks.
                </p>

              </div>
            </div>
            <!-- single services -->
          </div>
          <div class="col-lg-4 col-md-7 col-sm-8">
            <div
                class="single-services feature text-center mt-30 wow fadeIn"
                data-wow-duration="1s"
                data-wow-delay="0.2s"
            >
              <div class="services-icon">

                <span class="feature_number">6</span>
              </div>
              <div class="services-content mt-30">
                <h6 class="services-title">
                  Mobile Applications
                </h6>
                <p class="text">
                  Work from anywhere anytime using our mobile applications.
                </p>

              </div>
            </div>
            <!-- single services -->
          </div>
        </div>
        <!-- row -->
      </div>
      <!-- container -->
    </section>

    <!-- Testimonials -->
    <section id="testimonial" class="testimonial-area pt-120">
      <div class="container">
        <div class="row justify-content-center">
          <div class="col-lg-5">
            <div class="section-title text-center pb-40">
              <div class="line m-auto"></div>
              <h3 class="title">Clients sharing<span> their experience</span></h3>
            </div>
            <!-- section title -->
          </div>
        </div>
        <div class="testimonial-wrapper">
          <!-- row -->
          <div
              class="row g-0 testimonial-active wow fadeInUpBig"
              data-wow-duration="1s"
              data-wow-delay="0.8s"
          >
            <div class="col-lg-4">
              <div class="single-testimonial">
                <div
                    class="
                    testimonial-review
                    d-flex
                    align-items-center
                    justify-content-between
                  "
                >
                  <div class="quota">
                    <i class="lni lni-quotation"> </i>
                  </div>
<!--                  <div class="star">
                    <ul>
                      <li><i class="lni lni-star-filled"> </i></li>
                      <li><i class="lni lni-star-filled"> </i></li>
                      <li><i class="lni lni-star-filled"> </i></li>
                      <li><i class="lni lni-star-filled"> </i></li>
                      <li><i class="lni lni-star-filled"> </i></li>
                    </ul>
                  </div>-->
                </div>
                <div class="testimonial-text">
                  <p class="text">
                    Ezen Real Time accounting provides us with insight into our business. We are confident with its reliable reporting tool. You've got it team.
                  </p>
                </div>
                <div class="testimonial-author d-flex align-items-center">
                  <div class="author-image">
                    <img
                        class="shape"
                        src="assets/images/testimonials/textimonial-shape.svg"
                        alt="shape"
                    />
                    <img
                        class="author"
                        src="assets/images/testimonials/avatar.png"
                        alt="author"
                    />
                  </div>
                  <div class="author-content media-body">
                    <h6 class="holder-name">Evelyn</h6>
                    <p class="text">Accountant, Gatatha Farmers</p>
                  </div>
                </div>
              </div>
              <!-- single testimonial -->
            </div>
            <div class="col-lg-4">
              <div class="single-testimonial">
                <div
                    class="
                    testimonial-review
                    d-flex
                    align-items-center
                    justify-content-between
                  "
                >
                  <div class="quota">
                    <i class="lni lni-quotation"> </i>
                  </div>
<!--                  <div class="star">
                    <ul>
                      <li><i class="lni lni-star-filled"> </i></li>
                      <li><i class="lni lni-star-filled"> </i></li>
                      <li><i class="lni lni-star-filled"> </i></li>
                      <li><i class="lni lni-star-filled"> </i></li>
                      <li><i class="lni lni-star-filled"> </i></li>
                    </ul>
                  </div>-->
                </div>
                <div class="testimonial-text">
                  <p class="text">
                    Took us a long way trying enterprise systems, that in some way or the other never worked. Felicitation to Ezen Financials. We have our business covered in a single software.
                  </p>
                </div>
                <div class="testimonial-author d-flex align-items-center">
                  <div class="author-image">
                    <img
                        class="shape"
                        src="assets/images/testimonials/textimonial-shape.svg"
                        alt="shape"
                    />
                    <img
                        class="author"
                        src="assets/images/testimonials/avatar.png"
                        alt="author"
                    />
                  </div>
                  <div class="author-content media-body">
                    <h6 class="holder-name">Samuel</h6>
                    <p class="text">MD, FamilyWings Consult</p>
                  </div>
                </div>
              </div>
              <!-- single testimonial -->
            </div>
            <div class="col-lg-4">
              <div class="single-testimonial">
                <div
                    class="
                    testimonial-review
                    d-flex
                    align-items-center
                    justify-content-between
                  "
                >
                  <div class="quota">
                    <i class="lni lni-quotation"> </i>
                  </div>
<!--                  <div class="star">
                    <ul>
                      <li><i class="lni lni-star-filled"> </i></li>
                      <li><i class="lni lni-star-filled"> </i></li>
                      <li><i class="lni lni-star-filled"> </i></li>
                      <li><i class="lni lni-star-filled"> </i></li>
                      <li><i class="lni lni-star-filled"> </i></li>
                    </ul>
                  </div>-->
                </div>
                <div class="testimonial-text">
                  <p class="text">
                    Ezen Financials combined with it's 24/7 support team is the best solution to business I've ever tried. Have a taste of it and you will understand my point here. Wohooo!
                  </p>
                </div>
                <div class="testimonial-author d-flex align-items-center">
                  <div class="author-image">
                    <img
                        class="shape"
                        src="/assets/images/testimonials/textimonial-shape.svg"
                        alt="shape"
                    />
                    <img
                        class="author"
                        src="/assets/images/testimonials/avatar.png"
                        alt="author"
                    />
                  </div>
                  <div class="author-content media-body">
                    <h6 class="holder-name">Isaac</h6>
                    <p class="text">Paragon Property Consultants Ltd</p>
                  </div>
                </div>
              </div>
              <!-- single testimonial -->
            </div>
            <div class="col-lg-4">
              <div class="single-testimonial">
                <div
                    class="
                    testimonial-review
                    d-flex
                    align-items-center
                    justify-content-between
                  "
                >
                  <div class="quota">
                    <i class="lni lni-quotation"> </i>
                  </div>
<!--                  <div class="star">
                    <ul>
                      <li><i class="lni lni-star-filled"> </i></li>
                      <li><i class="lni lni-star-filled"> </i></li>
                      <li><i class="lni lni-star-filled"> </i></li>
                      <li><i class="lni lni-star-filled"> </i></li>
                      <li><i class="lni lni-star-filled"> </i></li>
                    </ul>
                  </div>-->
                </div>
                <div class="testimonial-text">
                  <p class="text">
                    Got stuck with manual records, as the business kept growing, the office got bigger and bigger. Not until we found the Ezen Team, truly they eased our business.
                  </p>
                </div>
                <div class="testimonial-author d-flex align-items-center">
                  <div class="author-image">
                    <img
                        class="shape"
                        src="assets/images/testimonials/textimonial-shape.svg"
                        alt="shape"
                    />
                    <img
                        class="author"
                        src="assets/images/testimonials/avatar.png"
                        alt="author"
                    />
                  </div>
                  <div class="author-content media-body">
                    <h6 class="holder-name">Cyrus</h6>
                    <p class="text">MasterWays Properties Limited</p>
                  </div>
                </div>
              </div>
              <!-- single testimonial -->
            </div>
          </div>
          <!-- row -->
        </div>
      </div>
      <!-- container -->
    </section>

  </div>
</template>

<script>
export default {
  name: "HomePage",
  metaInfo: {
    // title will be injected into parent titleTemplate
    title: 'Ezen Financials'
  },
  components: {},
  mounted() {

    try {
      window.tns({
        container: ".testimonial-active",
        autoplay: true,
        autoplayTimeout: 5000,
        autoplayButtonOutput: false,
        mouseDrag: true,
        gutter: 24,
        nav: true,
        navPosition: "bottom",
        controls: false,
        controlsText: [
          '<i class="lni lni-chevron-left"></i>',
          '<i class="lni lni-chevron-right"></i>',
        ],
        items: 1,
        responsive: {
          768: {
            items: 2,
          },
          992: {
            items: 3,
          },
        },
      });
    } catch (e) {
      console.error(e)
    }

    if (document.getElementById("particles-1"))
      window.particlesJS("particles-1", {
        particles: {
          number: {
            value: 40,
            density: {
              enable: !0,
              value_area: 4000,
            },
          },
          color: {
            value: ["#FFFFFF", "#FFFFFF", "#FFFFFF"],
          },
          shape: {
            type: "circle",
            stroke: {
              width: 0,
              color: "#fff",
            },
            polygon: {
              nb_sides: 5,
            },
            image: {
              src: "img/github.svg",
              width: 33,
              height: 33,
            },
          },
          opacity: {
            value: 0.15,
            random: !0,
            anim: {
              enable: !0,
              speed: 0.2,
              opacity_min: 0.15,
              sync: !1,
            },
          },
          size: {
            value: 50,
            random: !0,
            anim: {
              enable: !0,
              speed: 2,
              size_min: 5,
              sync: !1,
            },
          },
          line_linked: {
            enable: !1,
            distance: 150,
            color: "#ffffff",
            opacity: 0.4,
            width: 1,
          },
          move: {
            enable: !0,
            speed: 1,
            direction: "top",
            random: !0,
            straight: !1,
            out_mode: "out",
            bounce: !1,
            attract: {
              enable: !1,
              rotateX: 600,
              rotateY: 600,
            },
          },
        },
        interactivity: {
          detect_on: "canvas",
          events: {
            onhover: {
              enable: !1,
              mode: "bubble",
            },
            onclick: {
              enable: !1,
              mode: "repulse",
            },
            resize: !0,
          },
          modes: {
            grab: {
              distance: 400,
              line_linked: {
                opacity: 1,
              },
            },
            bubble: {
              distance: 250,
              size: 0,
              duration: 2,
              opacity: 0,
              speed: 3,
            },
            repulse: {
              distance: 400,
              duration: 0.4,
            },
            push: {
              particles_nb: 4,
            },
            remove: {
              particles_nb: 2,
            },
          },
        },
        retina_detect: !0,
      });
  }
}
</script>

<style scoped>

.video-wrapper .video-icon {
  background: -webkit-gradient(linear, left top, right top, from(rgba(220, 53, 69, 0.2)), to(rgba(155, 9, 23, 0.2)));
  background: linear-gradient(to right, rgba(220, 53, 69, 0.2) 0%, rgba(155, 9, 23, 0.2) 100%);
}

.header-hero-content .header-sub-title {
  font-size: 22px;
  font-weight: 300;
  color: #fff;
  line-height: 35px;
}

.header-hero-image {
  padding-top: 45px;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
}

.video-wrapper .video-icon a {
  color: #c21a1a !important;
}

.feature_number {
  font-size: 20px;
  background: linear-gradient(to right, #9b0917 0%, #950833 50%, #2a0004 100%);
  background-size: 200%;
  width: 50px;
  height: 50px;
  border-radius: 50%;
  color: white;
  line-height: 47px;
}

.single-services.feature {
  box-shadow: none;
}

.single-testimonial .testimonial-author .author-image .author {
  border-radius: 50%;
}

.single-services.feature:hover {
  cursor: pointer;
  border-color: transparent;
  -webkit-box-shadow: 0px 5px 30px 0px rgba(151, 9, 40, 0.16);
  box-shadow: 0px 5px 30px 0px rgba(151, 9, 40, 0.16);
}

.single-testimonial:hover {
  border-color: #950833;
}

.single-testimonial:hover .testimonial-review .quota i {
  color: #950833;
}

.testimonial-wrapper .tns-nav button {
  background: -webkit-gradient(linear, left top, left bottom, from(#9b0917), to(#950833));
  background: linear-gradient(
      180deg, #9b0917 0%, #950833 100%);
}

.single-logo img {
  height: 72px;
}

.video-wrapper .video-icon a {
  width: 80px;
  height: 80px;
  line-height: 80px;
}

</style>