<template>
<div>

    <div class="container">
      <div class="row justify-content-center pt-100">
        <div class="col-lg-8">
          <div class="text-center">
            <h3
                class="header-sub-title wow fadeInUp"
                data-wow-duration="1.3s"
                data-wow-delay="0.2s"
            >
              Incase of any queries or feedback.
            </h3>
            <h2
                class="header-title wow fadeInUp"
                data-wow-duration="1.3s"
                data-wow-delay="0.5s"
            >
              Feel free to contact us and we will get back to you soonest!
            </h2>
            <!--              <p
                              class="text wow fadeInUp"
                              data-wow-duration="1.3s"
                              data-wow-delay="0.8s"
                          >
                            Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed
                            diam nonumy eirmod tempor
                          </p>-->
          </div>
          <!-- header hero content -->
        </div>
      </div>
      <!-- row -->

      <!-- row -->
    </div>


  <div class="container pt-90">
    <div class="row">
      <div class="col-md-12">
        <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3988.8218655288406!2d36.81870921593278!3d-1.2805569990660093!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x182f10d4bfbe51d1%3A0x7d2de50c6584c74c!2sEzen%20Financials!5e0!3m2!1sen!2ske!4v1635695485502!5m2!1sen!2ske" width="100%" height="450" style="border:0;" allowfullscreen="" loading="lazy"></iframe>
      </div>
    </div>
  </div>

  <div class="container">
    <div class="row justify-content-center">
      <div class="col-lg-4 col-md-4 col-sm-12">
        <div class="single-services mt-30 wow fadeIn" data-wow-duration="1s" data-wow-delay="0.2s" style="visibility: visible; animation-duration: 1s; animation-delay: 0.2s; animation-name: fadeIn;">
          <div class="services-content contact mt-30">
            <h4 class="services-title">
              Inquire more about Ezen
            </h4>
            <div class="info mt-5">
              <div class="address">
                <i class="lni lni-pin"></i>
                <h4>Location:</h4>
                <p><b>7th Floor, Twiga Towers, Muranga Road- Nairobi</b></p>
              </div>
              <div class="email">
                <i class="lni lni-envelope"></i>
                <h4>Email:</h4>
                <p class="mb-0"><b>info@ezenfinancials.com</b></p>
              </div>
              <div class="phone">
                <i class="lni lni-phone"></i>
                <h4>Call:</h4>
                <p class="mb-0"><b>(+254)729 523 191</b></p>
                <p class="mb-0"><b>(+254)720 893 752</b></p>
              </div>
            </div>
          </div>
        </div>
        <!-- single services -->
      </div>
      <div class="col-lg-8 col-md-8 col-sm-12">
        <div class="single-services mt-30 wow fadeIn" data-wow-duration="1s" data-wow-delay="0.5s" style="visibility: visible; animation-duration: 1s; animation-delay: 0.5s; animation-name: fadeIn;">
          <div class="alert alert-warning" v-if="submitting">
            Sending, please wait....
          </div>
          <div class="alert alert-info" v-if="message!=null">
            {{message}}
          </div>
          <div class="services-content">
            <form role="form" @submit.prevent="sendEmail()" ref="form" label-position="left"  class="php-email-form">
              <div class="row">
                <div class="form-group col-md-6">
                  <label for="name"><b>Your Name</b></label>
                  <input type="text" v-model="form.names" class="form-control" id="name" required="">
                </div>
                <div class="form-group col-md-6">
                  <label for="name"><b>Your Email</b></label>
                  <input type="email" class="form-control"  v-model="form.email" id="email" required="">
                </div>
              </div>
              <div class="form-group mt-3">
                <label for="name"><b>Subject</b></label>
                <input type="text" class="form-control" v-model="form.subject" id="subject" required="">
              </div>
              <div class="form-group mt-3">
                <label for="name"><b>Message</b></label>
                <textarea class="form-control" v-model="form.message" rows="3" required=""></textarea>
              </div>
              <button class="btn btn-primary mt-4 cursor-pointer" type="submit">Send Message</button>
            </form>
          </div>
        </div>
        <!-- single services -->
      </div>
    </div>
  </div>

</div>
</template>

<script>
import axios from 'axios';

export default {
  name: "ContactPage",
  metaInfo: {
    // title will be injected into parent titleTemplate
    title: 'Contact Us'
  },
  data (){
    return {
      endpoint: "http://ezenfinancials.com/apis/contact_us.php",
      message: null,
      submitting: false,
      form: {
        names: null,
        email: null,
        subject: null,
        message: null
      }
    }
  },
  methods: {
    resetForm: function(){
      this.form={names: null, email: null,subject: null,message: null};
      this.message=null;
      
    },
    async sendEmail() {
        try{
            this.submitting = true;
            await axios.post('https://ezenfinancials.com/apis/contact.php', {
                    name: this.form.names,
                    email: this.form.email,
                    subject: this.form.subject,
                    message: this.form.message
                }
            )
            .then((result)=>{
                this.message = result.data;
            })
            .finally(()=>{
              this.form.names = null;
              this.form.email = null;
              this.form.subject = null;
              this.form.message = null;
              this.submitting = false;
              // this.message = null;
            })
        }
        catch(error) {
            console.log(error);
        }
    }
  }
}
</script>

<style scoped>
.contact .info i {
  font-size: 20px;
  color: #c21a1a;
  float: left;
  width: 44px;
  height: 44px;
  background: #FFCCCB;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50px;
  transition: all 0.3s ease-in-out;
}
.contact .info h4 {
  padding: 0 0 0 60px;
  font-size: 22px;
  font-weight: 600;
  margin-bottom: 5px;
  color: #c21a1a;
}

.contact .info p {
  padding: 0 0 10px 60px;
  margin-bottom: 20px;
  font-size: 14px;
  color: #3d3c3a;
}
.contact .info .email:hover i, .contact .info .address:hover i, .contact .info .phone:hover i {
  background: #c21a1a;
  color: #fff;
}

.form-control {
  border: none;
  border-radius: 0;
  border-bottom: solid 1px #9b0917;
}

.form-control:focus {
  color: #212529;
  background-color: #fff;
  border-color: inherit;
  outline: 0;
  box-shadow: none;
}

.btn-primary {
  border-color: #950833;
  background-color: #950833;
}

.btn-primary:hover {
  border-color: #950833;
  background-color: #950833;
}
</style>