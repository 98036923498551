<template>
<div>
  <div class="container">
    <div class="row justify-content-center pt-100 mb-100">
      <div class="col-lg-8">
        <div class="text-center">

          <h2
              class="header-title wow fadeInUp"
              data-wow-duration="1.3s"
              data-wow-delay="0.5s"
          >
           FAQs
          </h2>
          <!--
            <p
                            class="text wow fadeInUp"
                            data-wow-duration="1.3s"
                            data-wow-delay="0.8s"
                        >
                          Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed
                          diam nonumy eirmod tempor
                        </p>
                        -->
        </div>
        <!-- header hero content -->
      </div>
    </div>
    <!-- row -->


    <div class="accordion accordion-flush" id="accordionFlushExample" style="margin-top:-3em">
      <div class="card fad-card" v-for="(faq, index) in faqs"  :key="'faq_'+index">
        <h2 class="" :id="'faq-'+index">
          <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" :data-bs-target="'#faqCollapse-'+index" aria-expanded="false" aria-controls="flush-collapseOne">
            {{faq.title}}
          </button>
        </h2>
        <div :id="'faqCollapse-'+index" class="accordion-collapse collapse" :aria-labelledby="'flush-heading'+index" data-bs-parent="#accordionFlushExample">
          <div class="accordion-body card-body">
            <p v-html="faq.content"></p>
          </div>
        </div>
      </div>
    </div>

    <!-- row -->
  </div>
</div>
</template>

<script>
export default {
  name: "FAQPage",
  metaInfo: {
    // title will be injected into parent titleTemplate
    title: 'FAQs'
  },
  data() {
    return {
      faqs: [
        {
          title: 'What is Ezen Financials?',
          content: `Ezen Financials is an all-in-one web based Financial ERP for Microfinance, Sacco, Property Management, Property sales, Valuation, CRM, HR & Payroll and Financial Accounting that makes business life easier for Saccos, Microfinance, Property managers, landlords and any other business.`
        },
        {
          title: 'How Secure is my data?',
          content: 'The data on the system is end-to-end encrypted by SSL certificate ensuring only authorized end users can access it.'
        },
        {
          title: 'Does the system have downtimes?',
          content: 'We have hosted the system on a cloud server which has several replications in different parts of the world. This ensures that in case one server goes down, the other ones kick in, avoiding any downtime possibilities.'
        },
        {
          title: 'Do I have to pay for any upgrades done in the system?',
          content: 'No. One of the benefits of using the system is that you get to benefit from the new features that are incorporated in the system as the days go by for free.'
        },
        {
          title: 'How often do you offer your customer support?',
          content: '24/7. Our team is always ready to support our client either onsite or remotely through a team viewer, zoom or phone calls.'
        },
        {
          title: 'Can I communicate to tenants and landlords through the system?',
          content: 'Yes! We have integrated the system with bulk SMS and Email to ease the process of sending reminders, receipts, notices and statements.'
        },
        {
          title: 'Can I export my data from the system?',
          content: 'All  reports in the system can be exported into any format ie. Excel, PDF, Word etc.'
        }
      ]
    }
  }
}
</script>

<style scoped>

.accordion-button {
  background-color: transparent;
  font-weight: bold;
  font-size: 20px;
}
.accordion-button:not(.collapsed) {
  color: #9b0917;
}
.accordion-button:focus {
  z-index: 3;
  border-color: transparent;
  outline: 0;
  box-shadow: none;
}

.fad-card {
  box-shadow: 0px 0px 18px 0px rgba(50, 77, 215, 0.14);
  border-radius: 10px;
  background-color: #fff;
  margin-bottom: 20px;
}
</style>