<template>
    <section id="home">
      <div class="bg-holder d-none d-md-block bg-size"></div>
      <div class="container">
        <div>
          <div class="row justify-content-center mb-6">
            <div class="col-lg-6 text-center mx-auto mb-3 mb-md-5 mt-4">
              <h5 class="fw-bold fs-3 fs-lg-5 lh-sm mb-3">Terms & Conditions</h5>
              <h6>Last updated: July 18, 2022</h6>
              <strong>Terms &amp; Conditions</strong>
              <div>
                  <strong>Definitions</strong>
                  <p>For the purposes of these Terms and Conditions:</p>
                      <p>
                        <b>Affiliate</b> means an entity that controls, is controlled 
                          by or is under common control with a party, where "control" 
                          means ownership of 50% or more of the shares, equity interest 
                          or other securities entitled to vote for election of directors 
                          or other managing authority.
                      </p>
                      
                      <p>
                          <b>Company</b> (referred to as either "the Company", "We", "Us" or "Our" in
                          this Agreement) refers to Ezen Partners Ltd.
                      </p>
                      <p>
                          <b>Device</b> means any device that can access the Service such as a computer, a 
                          cellphone or a digital tablet.
                      </p>
                      <p>
                          <b>Service</b> refers to Ezen Agents Mobile Application.
                      </p>
                      <p>
                          <b>Terms and Conditions</b> (also referred as "Terms") mean these Terms and 
                          Conditions that form the entire agreement between You and the Company regarding 
                          the use of the Service.
                      </p>
                      <p>
                          <b>Third-party Social Media Service</b> means any services or content (including 
                          data, information, products or services) provided by a third-party that may be 
                          displayed, included or made available by the Service.
                      </p>
                      <p>
                          <b>You</b> means the individual accessing or using the Service, or the company, 
                          or other legal entity on behalf of which such individual is accessing or using 
                          the Service, as applicable.
                      </p>
              </div>
              <div>
                  <strong>Acknowledgment</strong>
                  <p>
                      These are the Terms and Conditions governing the use of this Service and the 
                      agreement that operates between You and the Company. These Terms and Conditions 
                      set out the rights and obligations of all users regarding the use of the Service.
                  </p>
                  <p>
                    Your access to and use of the Service is conditioned on Your acceptance of 
                    and compliance with these Terms and Conditions. These Terms and Conditions 
                    apply to all visitors, users and others who access or use the Service.
                  </p>
                  <p>
                      By accessing or using the Service You agree to be bound by these 
                      Terms and Conditions. If You disagree with any part of these Terms 
                      and Conditions then You may not access the Service.
                  </p>
                  <p>
                    You represent that you are over the age of 18. The Company 
                    does not permit those under 18 to use the Service.
                  </p>
                  <p>
                    Your access to and use of the Service is also conditioned on 
                    Your acceptance of and compliance with the Privacy Policy of 
                    the Company. Our Privacy Policy describes Our policies and 
                    procedures on the collection, use and disclosure of Your 
                    personal information when You use the Application or the 
                    Website and tells You about Your privacy rights and how the 
                    law protects You. Please read Our Privacy Policy 
                    carefully before using Our Service.
                  </p>
              </div>
              <strong>Terms &amp; Conditions</strong>
              <p>
                By downloading or using the app, these terms will automatically
                apply to you – you should make sure therefore that you read them
                carefully before using the app. You’re not allowed to copy, or
                modify the app, any part of the app, or our trademarks in any
                way. You’re not allowed to attempt to extract the source code of
                the app, and you also shouldn’t try to translate the app into
                other languages, or make derivative versions. The app itself,
                and all the trade marks, copyright, database rights and other
                intellectual property rights related to it, still belong to
                Ezen.
              </p>
              <p>
                Ezen is committed to ensuring that the app is as useful and
                efficient as possible. For that reason, we reserve the right to
                make changes to the app or to charge for its services, at any
                time and for any reason. We will never charge you for the app or
                its services without making it very clear to you exactly what
                you’re paying for.
              </p>
              <p>
                At some point Ezen Agents app stores and processes personal data that you
                have provided to us, in order to provide our Service. It’s your
                responsibility to keep your phone and access to the app secure.
                We therefore recommend that you do not jailbreak or root your
                phone, which is the process of removing software restrictions
                and limitations imposed by the official operating system of your
                device. It could make your phone vulnerable to
                malware/viruses/malicious programs, compromise your phone’s
                security features and it could mean that the Ezen Agents app won’t
                work properly or at all.
              </p>
              <div>
                <p>
                  The app does use third party services that declare their own
                  Terms and Conditions.
                </p>
                <p>
                  Link to Terms and Conditions of third party service providers
                  used by the app
                </p>
                <ul>
                  <li>
                    <a
                      href="https://policies.google.com/terms"
                      target="_blank"
                      rel="noopener noreferrer"
                      >Google Play Services</a
                    >
                  </li>
                </ul>
              </div>
              <p>
                You should be aware that there are certain things that Ezen
                will not take responsibility for. Certain functions of the app
                will require the app to have an active internet connection. The
                connection can be Wi-Fi, or provided by your mobile network
                provider, but Ezen cannot take responsibility for the app not
                working at full functionality if you don’t have access to Wi-Fi,
                and you don’t have any of your data allowance left.
              </p>
              <p></p>
              <p>
                If you’re using the app outside of an area with Wi-Fi, you
                should remember that your terms of the agreement with your
                mobile network provider will still apply. As a result, you may
                be charged by your mobile provider for the cost of data for the
                duration of the connection while accessing the app, or other
                third party charges. In using the app, you’re accepting
                responsibility for any such charges, including roaming data
                charges if you use the app outside of your home territory (i.e.
                region or country) without turning off data roaming. If you are
                not the bill payer for the device on which you’re using the app,
                please be aware that we assume that you have received permission
                from the bill payer for using the app.
              </p>
              <p>
                Along the same lines, Ezen cannot always take responsibility
                for the way you use the app i.e. You need to make sure that your
                device stays charged – if it runs out of battery and you can’t
                turn it on to avail the Service, Ezen cannot accept
                responsibility.
              </p>
              <p>
                With respect to Ezen’s responsibility for your use of the app,
                when you’re using the app, it’s important to bear in mind that
                although we endeavour to ensure that it is updated and correct
                at all times, we do rely on third parties to provide information
                to us so that we can make it available to you. Ezen accepts no
                liability for any loss, direct or indirect, you experience as a
                result of relying wholly on this functionality of the app.
              </p>
              <p>
                At some point, we may wish to update the app. The app is
                currently available on Android &amp; iOS – the requirements for
                both systems(and for any additional systems we decide to extend
                the availability of the app to) may change, and you’ll need to
                download the updates if you want to keep using the app. Ezen
                does not promise that it will always update the app so that it
                is relevant to you and/or works with the Android &amp; iOS
                version that you have installed on your device. However, you
                promise to always accept updates to the application when offered
                to you, We may also wish to stop providing the app, and may
                terminate use of it at any time without giving notice of
                termination to you. Unless we tell you otherwise, upon any
                termination, (a) the rights and licenses granted to you in these
                terms will end; (b) you must stop using the app, and (if needed)
                delete it from your device.
              </p>
              <div>
                  <strong>Governing Law</strong>
                  <p>
                      The laws of the Country, excluding its conflicts of law rules, 
                      shall govern this Terms and Your use of the Service. Your use 
                      of the Application may also be subject to other local, state, 
                      national, or international laws.
                  </p>
              </div>
              <div>
                  <strong>Severability</strong>
                  <p>
                      If any provision of these Terms is held to be unenforceable 
                      or invalid, such provision will be changed and interpreted to 
                      accomplish the objectives of such provision to the greatest 
                      extent possible under applicable law and the remaining provisions 
                      will continue in full force and effect.
                  </p>
              </div>
              <p><strong>Changes to This Terms and Conditions</strong></p>
              <p>
                We may update our Terms and Conditions from time to time. Thus,
                you are advised to review this page periodically for any
                changes. We will notify you of any changes by posting the new
                Terms and Conditions on this page.
                Any changes will be communicated atleast 30 days in advance.
              </p>
              <p>These terms and conditions are effective as of 2021-04-15</p>
              <p><strong>Contact Us</strong></p>
              <p>
                If you have any questions or suggestions about our Terms and
                Conditions, do not hesitate to contact us at
                support@ezenfinancials.com
              </p>
            </div>
          </div>
        </div>
      </div>
    </section>
</template>

<script>
export default {
    name: "TermsOfService",
    meta: {
        title: 'Terms Of Service'
    }
}
</script>