<template>
  <div>
    <div class="preloader">
      <div class="loader">
        <div class="spinner">
          <div class="spinner-container">
            <div class="spinner-rotator">
              <div class="spinner-left">
                <div class="spinner-circle"></div>
              </div>
              <div class="spinner-right">
                <div class="spinner-circle"></div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <HeaderNavigation />
    <router-view></router-view>
    <Footer />

    <a href="#" class="back-to-top"> <i class="lni lni-chevron-up"> </i> </a>

    <portal-target name="destination">
      <!--
      This component can be located anywhere in your App.
      The slot content of the above portal component will be rendered here.
      -->
    </portal-target>

  </div>
</template>

<style scoped>
.preloader .loader .spinner-circle {
  border-color: #c21a1a #b10000 #E1E1E1;
}
</style>

<script>

import HeaderNavigation from "@/components/Header";
import Footer from "@/components/Footer";
export default {
  name: 'App',
  components: {
    Footer,
    HeaderNavigation

  },
  created() {
    const scripts = [
      "/assets/js/main.js"
    ];
    scripts.forEach(script => {
      let tag = document.createElement("script");
      tag.setAttribute("src", script);
      document.head.appendChild(tag);
    });
  }
}
</script>

<style>
.btn-primary {
  border-color: #950833;
  background-color: #950833;
}

.btn-primary:hover {
  border-color: #950833;
  background-color: #950833;
}
</style>
