<template>
    <section id="facts" class="video-counter pt-70">
    <div class="container">
      <div class="row">
        <div class="col-lg-12 order-lg-last">
            <div
              class="counter-wrapper mt-50 wow fadeIn"
              data-wow-duration="1s"
              data-wow-delay="0.8s"
            >
                <div class="counter-content">
                    <div class="section-title">
                        <div class="line"></div>
                        <h3 class="title"> <span> Privacy Policy</span></h3>
                    </div>
                <!-- section title --> 
                <div>
                    <h5 class="mt-3">Messaging and Calls</h5>
                    <p class="text mt-3">
                        We utilize available message sending and calling functionality so as to 
                        ease service availability through reminders i.e pay rent on time.
                    </p>
                    <p>
                        However, our clients may occasionally require phone numbers from their 
                        tenants to send SMS reminders for rent billing and collection, this is an 
                        agreement between the client and their tenants.
                    </p>
                    <h5 class="mt-3">Device Information</h5>
                    <p>
                        We collect information from your device in some cases. The information will 
                        be utilized for the provision of better service and to prevent fraudulent acts.
                    </p>
                    <p>
                        Additionally, such information will not include that which will identify the 
                        individual user.
                    </p>
                    <h5 class="mt-3">Service Providers</h5>
                    <p class="mt-3">
                        We may employ third-party companies and individuals due to the following reasons:
                    </p>
                    <ol class="mt-3">
                        <li>To facilitate our Service;</li>
                        <li>To provide the Service on our behalf;</li>
                        <li>To perform Service-related services; or</li>
                        <li>To assist us in analyzing how our Service is used.</li>
                    </ol>
                    <p class="mt-3">
                        We want to inform users of this Service that these third parties have 
                        access to your Personal Information. 
                        The reason is to perform the tasks assigned to them on our behalf. 
                        However, they are obligated not to disclose or use the information for 
                        any other purpose.
                    </p>
                    <h5 class="mt-3">Information Collection and Use</h5>
                    <p class="mt-3">
                        For a better experience while using our Service, we may require you to provide 
                        us with certain personally identifiable information, including but not limited 
                        to users name, email address, gender, location, pictures.
                    </p>
                    <p class="mt-3">
                        The information that we request will be retained by us and used as described in 
                        this privacy policy. The app does use third party services that may collect information used to 
                        identify you.
                    </p>
                </div>
                </div>
          </div>
          <!-- counter wrapper -->
        </div>
      </div>
      <!-- row -->
    </div>
    <!-- container -->
  </section>
</template>
<script>
export default {
    name: "PrivacyPolicy",
    meta: {
        title: 'Privacy Policy'
    }
}
</script>