<template>
    <div class="container">
        <div class="row justify-content-center pt-100">
            <h1>404</h1>
            <h3 class="text-grey-500">This page has no power!</h3>
            <strong>Don't worry, Bad things happen to good people!</strong>
            <img src="assets/images/404/404page.jpg" width="100%" height="450" />
        </div>
    </div>
</template>

<script>
export default {
    name:"NotFound",
}
</script>