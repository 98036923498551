import Vue from 'vue'
import VueRouter from 'vue-router'
import HomePage from "@/pages/HomePage";
import ContactPage from "@/pages/ContactPage";
import FAQPage from "@/pages/FAQPage";
import FeaturesPage from "@/pages/FeaturesPage";
import AboutPage from "@/pages/AboutPage";
import VueMeta from 'vue-meta'
import SolutionsPage from "@/pages/SolutionsPage";
import NotFound from '@/pages/NotFound';
import PrivacyPolicy from '@/pages/PrivacyPolicy'
import NewPrivacyPolicy from '@/pages/NewPrivacyPolicy'
import TermsOfService from '@/pages/TermsOfService'
import FileHistory from '@/pages/FileHistory'

Vue.use(VueMeta)
Vue.use(VueRouter)


const routes = [
    {
        path: '/',
        name:'homepage',
        component: HomePage
    },
    {
        path: '/about',
        name:'aboutpage',
        component: AboutPage
    },
    {
        path: '/contact',
        name:'contactpage',
        component: ContactPage
    },
    {
        path: '/solutions',
        name:'solutionspage',
        component: SolutionsPage
    },
    {
        path: '/faq',
        name:'faqpage',
        component: FAQPage
    },
    {
        path: '/features',
        name:'featurepage',
        component: FeaturesPage
    },
    {
        path: '/privacy_policy',
        name:'privacypolicy',
        component: PrivacyPolicy
        // component: () => 
        //     import(/* webpackChunkName: "privacy" */ "./pages/PrivacyPolicy")
    },
    {
        path: '/new_privacy_policy',
        name: 'newprivacypolicy',
        component: NewPrivacyPolicy
    },
    {
        path: '/terms_of_service',
        name: 'termsofservice',
        component: TermsOfService
    },
    {
        path: '/files',
        name:'filehistory',
        component: FileHistory
    },
    {
        path: "/:pathMatch(.*)*",
        name:'NotFound',
        component: NotFound
    }
]

// 3. Create the router instance and pass the `routes` option
// You can pass in additional options here, but let's
// keep it simple for now.
const router = new VueRouter({
    mode: 'history',
    linkExactActiveClass: "active",
    // eslint-disable-next-line no-unused-vars
    scrollBehavior (to, from, savedPosition) {
        if (to.hash) {
            return document.querySelector(to.hash).scrollIntoView({ behavior: 'smooth' });
        } else {
            return savedPosition || { x: 0, y: 0 }
        }
    },
    // linkActiveClass: "active",
    routes // short for `routes: routes`
})

export default router;