<template>
  <div
      class="single-team text-center mt-30 wow fadeIn"
      data-wow-duration="1s"
      data-wow-delay="0.2s"
  >
    <div class="team-image">
      <img :src="image" :alt="name" />
<!--      <div class="social">
        <ul>
          <li v-for="(social, provider) in socials" :key="provider">
            <a :href="social" target="_blank">
              <i v-if="provider === 'facebook'" class="lni lni-facebook-filled"> </i>
              <i v-if="provider === 'twitter'" class="lni lni-twitter-filled"> </i>
              <i v-if="provider === 'instagram'" class="lni lni-instagram-filled"> </i>
              <i v-if="provider === 'linkedin'" class="lni lni-linkedin-original"> </i>
            </a>
          </li>
        </ul>
      </div>-->
    </div>
    <div class="team-content">
      <h5 class="holder-name">
        <a href="javascript:void(0)">{{name}}</a>
      </h5>
      <p class="text">{{position}}</p>
    </div>
  </div>
</template>

<script>
export default {
  name: "TeamMember",
  props: ['name', 'position', 'image', 'socials']
}
</script>

<style scoped>
.single-team .team-image .social ul::before {
  background: -webkit-gradient(linear, left top, left bottom, from(#9b0917), to(#fe6e9a));
  background: linear-gradient(#9b0917 0%, #fe6e9a 100%);
}
</style>