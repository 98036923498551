<template>
  <div>
    <a
        class="main-btn"
        data-scroll-nav="0"
        href="#"
        data-bs-toggle="modal"
        data-bs-target="#requestDemoModal"
        rel="nofollow"
    >
      Request Demo
    </a>

    <portal to="destination">
      <div class="modal fade" id="requestDemoModal" tabindex="-1" aria-labelledby="requestDemoModalLabel" aria-hidden="true">
        <div class="modal-dialog modal-dialog-centered">
          <div class="modal-content">
            <div class="modal-header">
<!--              <h5 class="modal-title" id="requestDemoModalLabel">Modal title</h5>-->
              <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <div class="modal-body">
              <h3 class="header-sub-title wow fadeInUp text-center mb-4">Request for a Demo</h3>
              <demo-form-component :form="requestFormData" />
            </div>
            <div class="modal-footer">
              <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Close</button>
              <!--button type="submit" class="btn btn-primary">Save changes</button-->
            </div>
          </div>
        </div>
      </div>
    </portal>
  </div>
</template>
<script>
import DemoFormComponent from "./forms/DemoFormComponent";
export default {
  name: "RequestDemo",
  components: {DemoFormComponent},
  data() {
    return {
      requestFormData: {}
    }
  }
};
</script>
<style scoped>

.modal-content {
  border: none;
  -webkit-box-shadow: 0px 0px 18px 0px rgba(50, 77, 215, 0.14);
  box-shadow: 0px 0px 18px 0px rgba(50, 77, 215, 0.14);
  border-radius: 10px;
  background-color: #fff;
}

.modal-header {
  border-bottom: none;
}
</style>