<template>
  <div>

<div class="container">
  <div class="row justify-content-center pt-100">
    <div class="col-lg-8">
      <div class="text-center">
        <h3
            class="header-sub-title wow fadeInUp"
            data-wow-duration="1.3s"
            data-wow-delay="0.2s"
        >
          Downloads
        </h3>
        <h2
            class="header-title wow fadeInUp"
            data-wow-duration="1.3s"
            data-wow-delay="0.5s"
        >
          
        </h2>
        <!--              <p
                          class="text wow fadeInUp"
                          data-wow-duration="1.3s"
                          data-wow-delay="0.8s"
                      >
                        Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed
                        diam nonumy eirmod tempor
                      </p>-->
      </div>
      <!-- header hero content -->
    </div>
  </div>
  <!-- row -->

  <!-- row -->
</div>
<div class="container pt-90">
    <div class="row text-center">
        <div class="col-md-12">
            <button id="button-three" class="btn btn-primary cursor-pointer" style="padding: 10px" @click="downloadFileTwo()">
              Ezen Audited Accounts 2021
            </button>
        </div>
        <div class="col-md-12" style="margin-top: 20px;">
          <button id="button-two" class="btn btn-primary cursor-pointer" style="padding: 10px;" @click="downloadFileOne()">
            Ezen Audited Accounts 2020
          </button>
        </div>
    </div>
</div>

</div>
</template>
    
<script>
import axios from 'axios';
export default {
    name: "filehistory",
    metaInfo: {
        // title will be injected into parent titleTemplate
        title: 'Downloads'
    },
    mounted() {
        // this.downloadFileOne();
    },
    methods: {
        downloadFileOne() {
            axios({
                    url: 'https://ezenfinancials.com/assets/AuditedAccounts2020.pdf', // File URL Goes Here
                    method: 'GET',
                    responseType: 'blob',
                }).then((res) => {
                    var FILE = window.URL.createObjectURL(new Blob([res.data], {type: 'application/pdf'}));
                    
                    var docUrl = document.createElement('a');
                    docUrl.href = FILE;
                    docUrl.setAttribute('download', 'Ezen-audited-accounts-2020.pdf');
                    document.body.appendChild(docUrl);
                    docUrl.click();
                    window.URL.revokeObjectURL(FILE);
                }).catch(console.error);
        },
        downloadFileTwo() {
            axios({
                    url: 'https://ezenfinancials.com/assets/AuditedACCOUNTS2021.pdf', // File URL Goes Here
                    method: 'GET',
                    responseType: 'blob',
                }).then((res) => {
                    var FILE = window.URL.createObjectURL(new Blob([res.data], {type: 'application/pdf'}));
                    
                    var docUrl = document.createElement('a');
                    docUrl.href = FILE;
                    docUrl.setAttribute('download', 'Ezen-audited-accounts-2021.pdf');
                    document.body.appendChild(docUrl);
                    docUrl.click();
                    window.URL.revokeObjectURL(FILE);
                }).catch(console.error);
        }
    }

}
</script>
    
<style scoped>

.accordion-button {
  background-color: transparent;
  font-weight: bold;
  font-size: 20px;
}
.accordion-button:not(.collapsed) {
  color: #9b0917;
}
.accordion-button:focus {
  z-index: 3;
  border-color: transparent;
  outline: 0;
  box-shadow: none;
}

.fad-card {
  box-shadow: 0px 0px 18px 0px rgba(50, 77, 215, 0.14);
  border-radius: 10px;
  background-color: #fff;
  margin-bottom: 20px;
}
/* <div class="" style="display: flex; flex-direction: row; margin-right: 10px;" id="downloads">
          <button id="button-two" class="main-btn p-2 px-4 py-2 rounded mr-4" type="button" @click="downloadFileOne()">
            Ezen Audited Accounts 2020
          </button>
          <button id="button-three" class="main-btn p-2 px-4 py-2 rounded cursor-pointer" type="button" @click="downloadFileTwo()">
            Ezen Audited Accounts 2021
          </button>
    </div> */
</style>