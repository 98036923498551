import Vue from 'vue'
import App from './App.vue'
import router from "@/router";
import store from "@/store";
import PortalVue from 'portal-vue';
Vue.use(PortalVue);
/*@import '../public/assets/css/animate.css';*/
/*@import '../public/assets/css/tiny-slider.css';*/
/*@import '../public/assets/css/glightbox.min.css';*/
/*@import '../public/assets/css/lineIcons.css';*/
/*@import '../public/assets/css/bootstrap.min.css';*/
/*@import '../public/assets/css/style.css';*/

Vue.config.productionTip = false

new Vue({
  router: router,
  store: store,
  render: h => h(App),
}).$mount('#app')
