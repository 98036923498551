<template>
  <div class="container">
    <div class="row justify-content-center pt-100 mb-100">
      <div class="col-lg-8">
        <div class="text-center">

          <h2
              class="header-title wow fadeInUp"
              data-wow-duration="1.3s"
              data-wow-delay="0.5s"
          >
            Ezen Solutions
          </h2>
          <!--
            <p
                            class="text wow fadeInUp"
                            data-wow-duration="1.3s"
                            data-wow-delay="0.8s"
                        >
                          Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed
                          diam nonumy eirmod tempor
                        </p>
                        -->
        </div>
        <!-- header hero content -->
      </div>
    </div>
    <!-- row -->

    <div id="sacco" class="about-area" style="margin-top:-3em">
      <div class="container">
        <div class="row">
          <div class="col-lg-6">
            <div
                class="about-content mt-30 wow fadeInLeftBig"
                data-wow-duration="1s"
                data-wow-delay="0.5s"
            >
              <div class="section-title">
                <div class="line"></div>
                <h3 class="title">
                  <span>Sacco & Microfinance</span>
                </h3>
              </div>
              <!-- section title -->
              <p class="text">
                Sacco ERP is an integrated Sacco and Microfinance Management System with a range of modules that enable a Sacco to manage its operations from membership management, savings and shares management, loan applications, disbursement, repayment , financial accounting, SASRA standard reports.
              </p>

              <img class="mt-5" src="/assets/img/pages/sacco-accounting-software.png" alt="sacco-accounting-software" />
              
            </div>
            <!-- about content -->
          </div>
          <div class="col-lg-6">
            <div
                class="about-image mt-30 wow fadeInRightBig"
                data-wow-duration="1s"
                data-wow-delay="0.5s"
            >
              <h5>Key features:</h5>

              <div class="row">

                <div class="col-sm-6">
                  <div class="single-services mt-10 wow fadeIn" data-wow-duration="1s" data-wow-delay="0.5s" style="visibility: visible; animation-duration: 1s; animation-delay: 0.5s; animation-name: fadeIn;">
                    <div class="services-content">
                      <h5 class="">
                        FOSA
                      </h5>
                      <span class="">Front Office Services Activity</span>
                      <p class="text">
                        Manage savings, deposits and withdrawals easily and conveniently.
                      </p>
                    </div>
                  </div>
                </div>

                <div class="col-sm-6">
                  <div class="single-services mt-30 wow fadeIn" data-wow-duration="1s" data-wow-delay="0.5s" style="visibility: visible; animation-duration: 1s; animation-delay: 0.5s; animation-name: fadeIn;">
                    <div class="services-content">
                      <h5 class="">
                        BOSA
                      </h5>
                      <span class="">Back Office Services Activity</span>
                      <p class="text">
                        Manage members’ savings and share contributions, loans and repayment with ease.
                      </p>
                    </div>
                  </div>
                </div>

                <div class="col-sm-6">
                  <div class="single-services mt-30 wow fadeIn" data-wow-duration="1s" data-wow-delay="0.5s" style="visibility: visible; animation-duration: 1s; animation-delay: 0.5s; animation-name: fadeIn;">
                    <div class="services-content">
                      <h5 class="">
                        Housing Co-op
                      </h5>
                      <span class="">Housing Cooperative</span>
                      <p class="text">
                        Manage members' shares contributions and calculate dividends.
                      </p>
                    </div>
                  </div>
                </div>

                <div class="col-sm-6">
                  <div class="single-services mt-30 wow fadeIn" data-wow-duration="1s" data-wow-delay="0.5s" style="visibility: visible; animation-duration: 1s; animation-delay: 0.5s; animation-name: fadeIn;">
                    <div class="services-content">
                      <h5 class="">
                        REITS
                      </h5>
                      <p class="text">
                        Manage investment in real estate easily.
                      </p>
                    </div>
                  </div>
                </div>

              </div>


            </div>
            <!-- about image -->
          </div>
        </div>
        <!-- row -->
      </div>
      <!-- container -->
      <div class="about-shape-1">
        <img src="assets/images/about/about-shape-1.svg" alt="shape" />
      </div>
    </div>

    <div id="property" class="about-area pt-70">
      <div class="container">
        <div class="row">
          <div class="col-lg-6">
            <div
                class="about-content mt-50 wow fadeInLeftBig"
                data-wow-duration="1s"
                data-wow-delay="0.5s"
            >
              <div class="section-title">
                <div class="line"></div>
                <h3 class="title">
                  <span>Property management, sales and valuation</span>
                </h3>
              </div>
              <!-- section title -->
              <p class="text">
                A tool that drives success for property owners, agencies, developers and property managers around the world.
              </p>

              <p class="text">
                Fully integrated with financial accounting.
              </p>

              <img class="mt-4" src="/assets/img/pages/ezenfinancials.com.jpg" alt="ezenfinancials" />


            </div>
            <!-- about content -->
          </div>
          <div class="col-lg-6">
            <div
                class="about-image mt-50 wow fadeInRightBig"
                data-wow-duration="1s"
                data-wow-delay="0.5s"
            >
              <h5>Key features</h5>

              <div class="row">

                <div class="col">
                  <div class="single-services mt-30 wow fadeIn" data-wow-duration="1s" data-wow-delay="0.5s" style="visibility: visible; animation-duration: 1s; animation-delay: 0.5s; animation-name: fadeIn;">
                    <div class="services-content">
                      <ul>
                        <li><i class="lni lni-checkmark"></i>Landlord management</li>
                        <li><i class="lni lni-checkmark"></i>Tenant and lease management</li>
                        <li><i class="lni lni-checkmark"></i>M-pesa integration</li>
                        <li><i class="lni lni-checkmark"></i>Bank integration</li>
                        <li><i class="lni lni-checkmark"></i>SMS integration</li>
                        <li><i class="lni lni-checkmark"></i>E-mail integration</li>
                        <li><i class="lni lni-checkmark"></i>Generate landlord reports</li>
                        <li><i class="lni lni-checkmark"></i>Mobile applications </li>
                      </ul>
                    </div>
                  </div>
                </div>

              </div>


            </div>
            <!-- about image -->
          </div>
        </div>
        <!-- row -->
      </div>
      <!-- container -->
      <div class="about-shape-1">
        <img src="assets/images/about/about-shape-1.svg" alt="shape" />
      </div>
    </div>

    <div id="hr" class="about-area pt-70">
      <div class="container">
        <div class="row">
          <div class="col-lg-6">
            <div
                class="about-content mt-50 wow fadeInLeftBig"
                data-wow-duration="1s"
                data-wow-delay="0.5s"
            >
              <div class="section-title">
                <div class="line"></div>
                <h3 class="title">
                  <span>Human Resource & Payroll</span>
                </h3>
              </div>
              <!-- section title -->
              <p class="text">
                Manage your human resources efficiently. Automate your payroll and save time. For smart HR and Payroll managers.              </p>

              <img class="mt-4" src="/assets/img/pages/human-resource-management-system.jpg" alt="human-resource-management-system" />

            </div>
            <!-- about content -->
          </div>
          <div class="col-lg-6">
            <div
                class="about-image mt-50 wow fadeInRightBig"
                data-wow-duration="1s"
                data-wow-delay="0.5s"
            >
              <h5>Key features</h5>

              <div class="row">

                <div class="col">
                  <div class="single-services mt-30 wow fadeIn" data-wow-duration="1s" data-wow-delay="0.5s" style="visibility: visible; animation-duration: 1s; animation-delay: 0.5s; animation-name: fadeIn;">
                    <div class="services-content">
                      <ul>
                        <li><i class="lni lni-checkmark"></i>Staff management.</li>
                        <li><i class="lni lni-checkmark"></i>Leave management.</li>
                        <li><i class="lni lni-checkmark"></i>Payroll processing and management</li>
                        <li><i class="lni lni-checkmark"></i>Statutory and tax compliance reports</li>
                        <li><i class="lni lni-checkmark"></i>Attendance management</li>
                        <li><i class="lni lni-checkmark"></i>Integration with biometrics attendance and access management devices.</li>
                        <li><i class="lni lni-checkmark"></i>Staff performance management</li>
                        <li><i class="lni lni-checkmark"></i>Integrated with bulk SMS</li>
                        <li><i class="lni lni-checkmark"></i>Integrated emailing</li>
                        <li><i class="lni lni-checkmark"></i>Staff mobile app and web portal</li>
                      </ul>

                    </div>
                  </div>
                </div>

              </div>


            </div>
            <!-- about image -->
          </div>
        </div>
        <!-- row -->
      </div>
      <!-- container -->
      <div class="about-shape-1">
        <img src="assets/images/about/about-shape-1.svg" alt="shape" />
      </div>
    </div>


    <div id="financial" class="about-area pt-70">
      <div class="container">
        <div class="row">
          <div class="col-lg-6">
            <div
                class="about-content mt-50 wow fadeInLeftBig"
                data-wow-duration="1s"
                data-wow-delay="0.5s"
            >
              <div class="section-title">
                <div class="line"></div>
                <h3 class="title">
                  <span>Financial Accounting</span>
                </h3>
              </div>
              <!-- section title -->
              <p class="text">
                Get the accounting results you want through our accounting tool that helps you track your finances, assets  and manage your business workflow.
              </p>

              <img class="mt-4" src="/assets/img/pages/ezenfinancials.com-accounts.jpg" alt="human-resource-management-system" />


            </div>
            <!-- about content -->
          </div>
          <div class="col-lg-6">
            <div
                class="about-image mt-50 wow fadeInRightBig"
                data-wow-duration="1s"
                data-wow-delay="0.5s"
            >
              <h5>Key features</h5>

              <div class="row">

                <div class="col">
                  <div class="single-services mt-30 wow fadeIn" data-wow-duration="1s" data-wow-delay="0.5s" style="visibility: visible; animation-duration: 1s; animation-delay: 0.5s; animation-name: fadeIn;">
                    <div class="services-content">

                      <ul>
                        <li><i class="lni lni-checkmark"></i>Easy invoicing</li>
                        <li><i class="lni lni-checkmark"></i>Receive payment anywhere anytime</li>
                        <li><i class="lni lni-checkmark"></i>Make payment easily</li>

                        <li><i class="lni lni-checkmark"></i>Manage customers and vendors</li>
                        <li><i class="lni lni-checkmark"></i>Bank reconciliations</li>
                        <li><i class="lni lni-checkmark"></i>Petty cash management</li>
                        <li><i class="lni lni-checkmark"></i>Calculate VAT returns</li>
                        <li><i class="lni lni-checkmark"></i>Fixed asset acquisition and depreciation processing.</li>
                        <li><i class="lni lni-checkmark"></i>Generate standard accounting reports for effective decision making</li>
                      </ul>

                    </div>
                  </div>
                </div>

              </div>


            </div>
            <!-- about image -->
          </div>
        </div>
        <!-- row -->
      </div>
      <!-- container -->
      <div class="about-shape-1">
        <img src="assets/images/about/about-shape-1.svg" alt="shape" />
      </div>
    </div>

    <div id="inventory" class="about-area pt-70">
      <div class="container">
        <div class="row">
          <div class="col-lg-6">
            <div
                class="about-content mt-50 wow fadeInLeftBig"
                data-wow-duration="1s"
                data-wow-delay="0.5s"
            >
              <div class="section-title">
                <div class="line"></div>
                <h3 class="title">
                  <span>Inventory Management & POS</span>
                </h3>
              </div>
              <!-- section title -->
              <p class="text">
                It’s important to evaluate your business regularly to ensure that you’re on track to succeed.
              </p>

              <p class="text">
                Have the right products in the right quantity on hand and avoid products being out of stock and funds being tied up in excess stock.
              </p>


            </div>
            <!-- about content -->
          </div>
          <div class="col-lg-6">
            <div
                class="about-image mt-50 wow fadeInRightBig"
                data-wow-duration="1s"
                data-wow-delay="0.5s"
            >
              <h5>Key features</h5>

              <div class="row">

                <div class="col">
                  <div class="single-services mt-30 wow fadeIn" data-wow-duration="1s" data-wow-delay="0.5s" style="visibility: visible; animation-duration: 1s; animation-delay: 0.5s; animation-name: fadeIn;">
                    <div class="services-content">

                      <ul>
                        <li><i class="lni lni-checkmark"></i>Inventory and stock management</li>
                        <li><i class="lni lni-checkmark"></i>Sales monitoring and reporting.</li>
                        <li><i class="lni lni-checkmark"></i>Billing and Order processing.</li>
                        <li><i class="lni lni-checkmark"></i>Customer relationship and experience</li>
                        <li><i class="lni lni-checkmark"></i>Cross channel returns management.</li>
                        <li><i class="lni lni-checkmark"></i>Easy inventory analysis</li>
                      </ul>

                    </div>
                  </div>
                </div>

              </div>


            </div>
            <!-- about image -->
          </div>
        </div>
        <!-- row -->
      </div>
      <!-- container -->
      <div class="about-shape-1">
        <img src="assets/images/about/about-shape-1.svg" alt="shape" />
      </div>
    </div>

    <div id="crm" class="about-area pt-70">
      <div class="container">
        <div class="row">
          <div class="col-lg-6">
            <div
                class="about-content mt-50 wow fadeInLeftBig"
                data-wow-duration="1s"
                data-wow-delay="0.5s"
            >
              <div class="section-title">
                <div class="line"></div>
                <h3 class="title">
                  <span>CRM</span>
                </h3>
              </div>
              <!-- section title -->
              <p class="text">
                Our CRM helps you streamline processes, manage customer relationships, increase sales, and better your customer service.
              </p>


            </div>
            <!-- about content -->
          </div>
          <div class="col-lg-6">
            <div
                class="about-image mt-50 wow fadeInRightBig"
                data-wow-duration="1s"
                data-wow-delay="0.5s"
            >
              <h5>Key features</h5>

              <div class="row">

                <div class="col">
                  <div class="single-services mt-30 wow fadeIn" data-wow-duration="1s" data-wow-delay="0.5s" style="visibility: visible; animation-duration: 1s; animation-delay: 0.5s; animation-name: fadeIn;">
                    <div class="services-content">

                      <ul>
                        <li><i class="lni lni-checkmark"></i>Accounts Management</li>
                        <li><i class="lni lni-checkmark"></i>Contacts Management</li>
                        <li><i class="lni lni-checkmark"></i>Interaction Tracking</li>
                        <li><i class="lni lni-checkmark"></i>Leads Management</li>
                        <li><i class="lni lni-checkmark"></i>Email and SMS Integration </li>
                        <li><i class="lni lni-checkmark"></i>Document management</li>
                        <li><i class="lni lni-checkmark"></i>Quotes/proposal management</li>
                        <li><i class="lni lni-checkmark"></i>Pipeline management</li>
                        <li><i class="lni lni-checkmark"></i>Reporting/analytics</li>

                      </ul>


                    </div>
                  </div>
                </div>

              </div>


            </div>
            <!-- about image -->
          </div>
        </div>
        <!-- row -->
      </div>
      <!-- container -->
      <div class="about-shape-1">
        <img src="assets/images/about/about-shape-1.svg" alt="shape" />
      </div>
    </div>



    <!-- row -->
  </div>
</template>

<style scoped>

</style>

<script>
export default {
  name: "SolutionsPage",
  metaInfo: {
    // title will be injected into parent titleTemplate
    title: 'Solutions'
  },
  data() {
    return {}
  },
  mounted() {
  }
}
</script>

<style scoped>
.lni.lni-checkmark{
  margin-right: 15px;
  margin-left: 15px;
  color: #950833;
  font-weight: bold;
}
</style>