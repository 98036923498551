<template>
  <header class="header-area">
    <div class="navbar-area" :class="{'sticky': currentRouteName !== 'homepage'}">
      <div class="container">
        <div class="row">
          <div class="col-lg-12">
            <nav class="navbar navbar-expand-lg">
              <router-link class="navbar-brand" to="/">
                <img v-if="currentRouteName === 'homepage'" src="/assets/img/logo/logo-2.svg" alt="Logo" />
                <img v-else src="/assets/img/logo/logo.svg" alt="Logo" />
              </router-link>
              <button
                  class="navbar-toggler"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#navbarSupportedContent"
                  aria-controls="navbarSupportedContent"
                  aria-expanded="false"
                  aria-label="Toggle navigation"
              >
                <span class="toggler-icon"> </span>
                <span class="toggler-icon"> </span>
                <span class="toggler-icon"> </span>
              </button>
              <Navigation />
              <!-- navbar collapse -->

              <div class="navbar-btn d-none d-sm-inline-block">
                <RequestDemo/>
              </div>
            </nav>
            <!-- navbar -->
          </div>
        </div>
        <!-- row -->
      </div>
      <!-- container -->
    </div>
    <!-- navbar area -->


    <!-- header hero -->
  </header>
</template>

<script>
import Navigation from "@/components/partials/Navigation";
import RequestDemo from "./RequestDemo";

export default {
  name: "HeaderNavigation",
  components: {RequestDemo, Navigation},
  computed: {
    currentRouteName() {
      return this.$route.name;
    }
  }
}
</script>

